import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Card = (props) => {

    return (
        <Link to={props.link}
              className="relative block shadow-xl w-full h-full cursor-pointer card">
            <BackgroundImage tag="div"
                            className="bg-cover bg-center w-full h-full"
                            fluid={props.image}>
                <div className="p-12 content md:py-16">
                    <p className="relative text-primary text-2xl text-3xl leading-snug word-spacing-screen font-serif z-10">{props.title}</p>
                </div>
            </BackgroundImage>
        </Link>
    )
};

export default Card;
