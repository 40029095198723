import React from "react";
import Card from "../components/card";

const CardGrid = (props) => {

    const cards = props.cards.map((card, index) =>
        <Card image={card.image} link={card.path} title={card.title} key={index}/>
    );

    return (
        <section className="mb-16 grid gap-x-12 gap-y-12 max-w-5xl container md:grid-cols-2 md:mb-32">
            {cards}
        </section>
    )
};

export default CardGrid;
