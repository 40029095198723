import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Masthead from "../components/masthead";
import Intro from "../components/intro";
import CardGrid from "../components/card-grid";
import BannerText from "../components/banner-text";

const GalleriesPage = ({data}) => {

    const galleries = data.galleries.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.image = obj.node.frontmatter.featured_image.childImageSharp.fluid;
        return rObj
    });

    const contact = {
        email: data.site.siteMetadata.email,
        tel: data.site.siteMetadata.telephone,
        mob: data.site.siteMetadata.mobile
    };

    return (
        <>
            <Layout>
                <SEO title="Galleries"/>
                <Masthead title="Galleries" image={data.background.childImageSharp.fluid}/>
                <Intro text="Explore our diverse range of project galleries."/>

                <CardGrid cards={galleries}/>

                <BannerText title="Request a quote"
                            summary={`Call our office <a href="tel:${contact.tel.replace(/\s+/g, '')}">${contact.tel}</a>, mobile <a href="tel:${contact.mob.replace(/\s+/g, '')}">${contact.mob}</a> or email <a href="mailto:${contact.email}?subject=Enquiry">${contact.email}</a>`}
                            background={data.backgroundPhones.childImageSharp.fluid}
                            arrowPosition="none"/>
            </Layout>
        </>
    )
}

export default GalleriesPage

export const query = graphql`
    query GalleriesPageQuery {
        site {
            siteMetadata {
                email
                telephone
                mobile
            }
        }
        background: file(absolutePath: {glob: "**/content/services/traditional-rendering/images/08.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1400, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgroundPhones: file(relativePath: {eq: "background-phones.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        galleries: allMarkdownRemark(filter: {fileAbsolutePath: {glob: "**/content/services/*/gallery/index.md"}}, sort: {fields: frontmatter___order}) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                        featured_image {
                            childImageSharp {
                                fluid(maxWidth: 800, quality: 90) {
                                  ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
